/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2024-07-17T18:49:20.193Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '24.3.10000-1488855';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
